<template>
  <div class="ecat-lightbox-gallery">
    <vue-easy-lightbox :visible="visible" :imgs="images" :index="internalCurrentIndex" @hide="hide">
      <template v-slot:toolbar>
        <div class="content-with-thumbnails">
          <div class="thumbnails">
            <div
                style="max-height: 50%"
                v-for="(image, index) in images"
                :key="index"
                @click="internalCurrentIndex = index"
                :class="{ active: index === internalCurrentIndex }"
                class="thumbnail">
              <img :src="image" alt="Thumbnail" />
            </div>
          </div>
        </div>
      </template>
    </vue-easy-lightbox>
  </div>
</template>

<script>
import VueEasyLightbox from "vue-easy-lightbox";

export default {
  components: {
    VueEasyLightbox,
  },

  props: {
    images: Array,
    visible: Boolean,
    currentIndex: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      internalCurrentIndex: this.currentIndex
    };
  },

  watch: {
    currentIndex(newIndex) {
      this.internalCurrentIndex = newIndex;
    },
  },

  methods: {
    hide() {
      this.internalCurrentIndex = 0;
      this.$emit("hide");
    },
  }
};
</script>

<style>
.ecat-lightbox-gallery .thumbnails {
  position: fixed;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.ecat-lightbox-gallery .thumbnails img {
  max-height: 100px;
  cursor: pointer;
}

.ecat-lightbox-gallery .active {
  border: 2px solid var(--primary);
}

.ecat-lightbox-gallery .thumbnail {
  max-height: 100px;
  cursor: pointer;
}
</style>